import React, {
  createContext,
  useContext,
  Dispatch,
  useReducer
} from 'react';
import { PageContext } from '../../types'

const initialState: PageContext = {}

export type SiteGlobals = {
  pageContextState?: PageContext,
  setSiteConfig?: Dispatch<SiteActions>
}

type SiteActions = { type: 'SET', payload: PageContext }

function reducer(state: PageContext, action: SiteActions) {
  if (action.type === 'SET') {
    return {
      ...state,
      ...action.payload
    }
  }
  return state;
}

const SiteContext = createContext<SiteGlobals>({});

const SiteProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SiteContext.Provider value={{ pageContextState: state, setSiteConfig: dispatch }}>
      {children}
    </SiteContext.Provider>
  )
}

const useGlobalStateContext = () => {
  return useContext(SiteContext);
}

export { useGlobalStateContext, SiteProvider };
