import React, { createContext, useContext, useState } from 'react';

type IToastState = {
    error?: boolean;
    message?: string;
    title?:string;
    show?: boolean;
}

type IToastMethod = {
  success?: (msg: string) => void;
  error?: (msg: string) => void;
  close?: () => void;
}
const ToastStateContext = createContext<IToastState>({});
const ToastContextMethods = createContext<IToastMethod>({});



const ToastProvider = ({ children }: { children: React.ReactChildren}) => {
  const [toastState, setToastState] = useState<IToastState>({
    error: false,
    message: '',
    title: '',
    show: false,
  });
  const [delayToast, setDelayToast] = useState<number>(0);

  const remove = () => {
    setToastState((prev) => ({
      ...prev,
      show: false,
      message: '',
      title: '',
    }));
  };

  const close = () => {
    delayToast && clearTimeout(delayToast);
    remove();
  };

  const success = (message: string, title = '') => {
    delayToast && clearTimeout(delayToast);
    setToastState((prev) => ({
      ...prev,
      error: false,
      show: true,
      message,
      title,
    }));
   
    setDelayToast(
       // @ts-ignore
      setTimeout(() => {
        remove();
      }, 7000)
    );
  };

  const error = (message: string, title = '') => {
    clearTimeout(delayToast);
    setToastState((prev) => ({
      ...prev,
      error: true,
      show: true,
      message,
      title,
    }));
    setDelayToast(
       // @ts-ignore
      setTimeout(() => {
        remove();
      }, 7000)
    );
  };

  const ToastMethods = {
    success,
    error,
    close,
  };

  return (
    <ToastStateContext.Provider
      value={{
        ...toastState,
      }}
    >
      <ToastContextMethods.Provider
       // @ts-ignore
         value={{ ...ToastMethods }}
      >
        {children}
      </ToastContextMethods.Provider>
    </ToastStateContext.Provider>
  );
};

function useToastState() {
  const context = useContext(ToastStateContext);
  return context;
}

function useToastFuncs() {
  const context = useContext(ToastContextMethods);
  return context;
}

export { ToastProvider, useToastFuncs, useToastState };
